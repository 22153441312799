<script>
export default {
  locales: {
    pt: {
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
      'setup': 'Adesão',
      'monthly': 'Mensalidade',
    },
    es: {
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
      'setup': 'Adesão',
      'monthly': 'Mensalidade',
    }
  },
  props: {
    type: {
      type: String,
      default: "default"
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  }
};
</script>

<template>
  <div class="card bg-white">
    <div class="card-body py-3">
      <div v-if="type == 'center'" class="text-center">
        <h5 v-if="value == 'active'" class="mb-0 notranslate text-white font-weight-bold"><span class="bg-success rounded text-dark px-2">ATIVO</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate text-white font-weight-bold"><span class="bg-danger rounded text-dark px-2">INATIVO</span></h5>
        <h5 v-else-if="value == 'pending'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-danger rounded text-white px-2">INATIVO</span></h5>
        <h5 v-else-if="value == 'approved'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">ATIVO</span></h5>
        <h5 v-else class="mb-0 notranslate text-dark font-weight-bold">{{ t(value) }}</h5>
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
      </div>
      <div v-else class="d-flex align-items-center justify-content-between">
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
        <h5 v-if="value == 'active'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">ATIVO</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-danger rounded text-white px-2">INATIVO</span></h5>
        <h5 v-else-if="value == 'pending'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-danger rounded text-white px-2">INATIVO</span></h5>
        <h5 v-else-if="value == 'approved'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">ATIVO</span></h5>
        <h5 v-else class="mb-0 notranslate text-dark font-weight-bold">{{ t(value) }}</h5>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg {
  background-color: #e0e0e0;
}
</style>