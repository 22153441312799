<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    Stat,
    TabView,
    TabPanel
  },
  data() {
    return {
      statData: null,
      assinatura: null,
      balance: {
        value: 'R$ ---,--',
        modal: false,
        loading: false,
      }
    };
  },
  methods: {
    getFatura() {
      api
        .get("assinatura/faturas/" + this.$route.params.id)
        .then((res) => {
          if (res.data.status == "success") {
            this.statData = [
              {
                title: "Código",
                value: res.data.assinatura.id.split('-')[0],
              },
              {
                title: "Tipo",
                value: res.data.assinatura.type,
              },
              {
                title: "Valor",
                value: this.$options.filters.currency(res.data.assinatura.value),
              },
            ];

            this.assinatura = res.data.assinatura;
          }
        })
        .catch((error) => {
          if (error) {
            this.$router.push('/configuracoes/assinatura');
          }
        })
        .finally(() => {
        });
    },
    getSaldo() {
      api.get("financeiro/saldo").then((res) => {
        if (res.data.status == "success") {
          this.balance.value = res.data.balance;
        }
      });
    },
    confirmBalance() {
      if (this.assinatura && this.assinatura.id) {
        this.balance.loading = true;

        api.post("assinatura/faturas/pagamento", {
          id: this.assinatura.id
        }).then((res) => {
          if (res.data.status == "success") {
            this.$toast.success(res.data.message);

            this.$router.push('/configuracoes/assinatura');
          } else {
            this.$toast.error(res.data.message);
          }
        }).finally(() => {
          this.balance.modal = false;
          this.balance.loading = false;
        });
      } else {
        this.getFatura();
        this.balance.modal = false;
      }
    }
  },
  mounted() {
    this.getFatura();
    this.getSaldo();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Minha Assinatura") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4 col-lg-4 col-xl-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="!assinatura" class="card">
      <div class="card-body text-center"><b-spinner class="align-middle" variant="default" role="status"></b-spinner></div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div v-if="assinatura && assinatura.payment">
          <TabView>
            <TabPanel header="PIX">
              <div class="text-center">
                <img style="width: 250px" :src="'data:image/jpeg;base64,' + assinatura.payment.base64" />
                <p>Leia o qrcode acima ou</p>
                <ol class="text-left">
                  <li>Abra o app do seu banco ou seu app de pagamentos.</li>
                  <li>Busque a opção de pagar com pix.</li>
                  <li>Copie e cole o seguinte código.</li>
                </ol>
                <div class="border rounded p-3 mb-3 text-left overflow-hidden">
                  {{ assinatura.payment.qrcode }}
                </div>
                <button class="btn btn-default" v-clipboard:copy="assinatura.payment.qrcode" v-on:click="$toast.success('Copiado!')">
                  <i class="bx bx-copy font-size-18 align-middle"></i>
                  Copiar
                </button>
              </div>
            </TabPanel>
            <TabPanel header="SALDO">
              <div class="mt-5 mb-3">
                <h5>Seu saldo atual é de:</h5>
                <h4 class="bg-soft-info d-inline-block mb-0 p-2 rounded">{{ balance.value | currency }}</h4>
              </div>
              <div v-if="(balance.value - assinatura.value) <= 0">
                <p class="text-danger">Você não possui saldo suficiente para realizar esse pagamento.</p>
              </div>
              <div v-else class="mb-5">
                <h5>Seu saldo após o pagamento será de:</h5>
                <h4 class="bg-soft-success d-inline-block mb-0 p-2 rounded">{{ (balance.value - assinatura.value) | currency }}</h4>
                <div class="mt-5">
                  <button class="btn btn-default btn-lg text-uppercase" v-on:click="balance.modal = true;">Confirmar</button>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>

    <b-modal v-model="balance.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Pagamento com saldo</h4>
        <p>Confirma o pagamento com saldo?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="confirmBalance">
            Confirmar
            <b-spinner v-if="balance.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <button class="btn btn-outline-link" v-on:click="balance.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
